import { useCallback, type PropsWithChildren } from 'react';
import { styled } from 'styled-components';
import { useToggle } from '@hn-front/utils';
import { Menu as Burger, Close, Human, Down, Up } from '@hn-ui/icons';
import { MenuItem, SetupClassButton } from './Common';
import { ParentsInfo } from './ParentsInfo';
import { AdminsInfo } from './AdminsInfo';
import { createPortal } from 'react-dom';
import type { IUserInfo } from 'app/models/auth';
import { teacherAuthUrls } from 'app/utils/urls.data';

const Container = styled.div`
  width: 20px;
  height: 20px;
`;

const BurgerWrapper = styled.button`
  cursor: pointer;
  background: none;
  padding: 0;
  border: none;
`;

const MenuItems = styled.div`
  display: flex;
  flex-flow: column;
`;

const MenuItemsContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 330px;
  position: absolute;
  right: 0;
  top: 100%;
  background-color: ${({ theme }) => theme.colors.black100};
  padding: 8px 16px;
  z-index: ${({ theme }) => theme.zIndex.header.menu};
  box-shadow: 0px 20px 28px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  transform: translateY(8px);

  ${({ theme: { down } }) => down('tabletMd')} {
    height: calc(100vh - 70px);
    transform: none;
    border-radius: unset;
  }

  ${({ theme: { down } }) => down('tabletSm')} {
    width: 328px;
  }

  ${({ theme: { down } }) => down('phone')} {
    width: 270px;
  }
`;

const SetupClass = styled(SetupClassButton)`
  height: 32px;
  gap: 4px;
  margin: 8px 0;
  width: fit-content;

  &:hover {
    color: inherit;
    background: inherit;
  }
`;

const ScMenuItem = styled(MenuItem)`
  height: 48px;

  &:hover {
    color: inherit;
    background: inherit;
  }
`;

const ExpandMenuItem = styled(ScMenuItem)`
  justify-content: space-between;
  width: 100%;
`;

const ScOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.header.overlay};
  background: rgba(0, 0, 0, 0.5);
  display: none;

  ${({ theme: { down } }) => down('tabletMd')} {
    display: block;
  }
`;

const Portal = ({ children }: PropsWithChildren) => {
  const root = document.getElementsByTagName('body')[0];
  if (!root) return <>{children}</>;

  return createPortal(<>{children}</>, root);
};

const Overlay = () => (
  <Portal>
    <ScOverlay />
  </Portal>
);

const DownIcon = () => <Down color="black50" size={16} />;
const UpIcon = () => <Up color="black50" size={16} />;

const ScParentsInfo = styled(ParentsInfo)`
  position: unset;
  transform: none;
  border-top: unset;
  box-shadow: unset;
  padding: 0;
  z-index: unset;
  margin: 8px 0px 16px;
`;

const ScAdminsInfo = styled(AdminsInfo)`
  position: unset;
  transform: none;
  border-top: unset;
  box-shadow: unset;
  padding: 0;
  z-index: unset;
  margin: 8px 0px 16px;
`;

const UserContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 4px;
  margin-bottom: 16px;
`;
const UserName = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UserEmail = styled.p`
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Separator = styled.div`
  width: calc(100% + 2 * 16px);
  height: 1px;
  background: ${({ theme }) => theme.colors.black90};

  transform: translate(-16px, 8px);
`;

const MainBurger = ({
  id,
  userEmail,
  userName,
  userType,
  ...rest
}: IUserInfo) => {
  const { isToggled: isParents, toggle: toggleParents } = useToggle();
  const { isToggled: isAdmins, toggle: toggleAdmins } = useToggle();
  const { isToggled: isMenuOpen, toggle: toggleMenu } = useToggle();

  const MenuIcon = isMenuOpen ? Close : Burger;

  const handleOnToggleParents = useCallback(() => {
    if (isAdmins) toggleAdmins();
    toggleParents();
  }, [toggleAdmins, toggleParents, isAdmins]);

  const handleOnToggleAdmins = useCallback(() => {
    if (isParents) toggleParents();
    toggleAdmins();
  }, [toggleAdmins, toggleParents, isParents]);

  const isSignedIn = !!id;

  return (
    <Container {...rest}>
      <BurgerWrapper
        type="button"
        data-testid="burger-button"
        aria-label={`${isMenuOpen ? 'close' : 'open'} menu`}
        onClick={toggleMenu}
      >
        <MenuIcon size={24} />
      </BurgerWrapper>
      {isMenuOpen && (
        <>
          <Overlay />
          <MenuItemsContainer>
            <MenuItems>
              {isSignedIn ? (
                <UserContainer>
                  <UserName>{userName}</UserName>
                  <UserEmail>{userEmail}</UserEmail>
                  <Separator />
                </UserContainer>
              ) : (
                <SetupClass href={teacherAuthUrls.signup}>
                  <Human color="black100" size={16} /> Set up your class
                </SetupClass>
              )}
              <ScMenuItem href="/math">Curriculum</ScMenuItem>
              <ScMenuItem href="/pricing">Pricing</ScMenuItem>
              <ScMenuItem
                as="button"
                href="#"
                className="open-intercom-messenger"
              >
                Contact us
              </ScMenuItem>
              <div>
                <ExpandMenuItem
                  as="button"
                  href=""
                  onClick={handleOnToggleParents}
                >
                  For Parents
                  {isParents ? <UpIcon /> : <DownIcon />}
                </ExpandMenuItem>
                {isParents && <ScParentsInfo />}
              </div>
              <div>
                <ExpandMenuItem
                  as="button"
                  href=""
                  onClick={handleOnToggleAdmins}
                >
                  For Admins
                  {isAdmins ? <UpIcon /> : <DownIcon />}
                </ExpandMenuItem>
                {isAdmins && <ScAdminsInfo />}
              </div>
            </MenuItems>
          </MenuItemsContainer>
        </>
      )}
    </Container>
  );
};

export { MainBurger };
